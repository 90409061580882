import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InternacaoService } from '../services/internacao.service';
import { CoreService } from '../services/core.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-medicacao-add',
  templateUrl: './medicacao-add.page.html',
  styleUrls: ['./medicacao-add.page.scss'],
})
export class MedicacaoAddPage implements OnInit {
  @Input() id: string;
  @Input() medico: string;
  @Input() paciente: string;

  medicacao: string;
  dosagem: string;


  constructor(
    private modalController: ModalController,
    private internacaoService: InternacaoService,
    private coreService: CoreService,
    private router: Router
  ) { }

  ngOnInit() {
    this.medicacao = '';
    this.dosagem = '';
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }

  async send() {
    const data = {
      medicamento: this.medicacao,
      dosagem: this.dosagem
    }
    try {
      const result = await this.internacaoService.addmedicacao(this.id, data);
      console.log(result)
      this.coreService.presentToast('Medicação registrada!', 'success')
      this.modalController.dismiss(null, 'submit')

    } catch (err) {
      console.log(err)
    } finally {
      console.log('finalmente ...')
    }
    // this.modalController.dismiss(null, 'submit');
  }
}
