import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from "@ionic-native/http/ngx";
import { Platform } from '@ionic/angular';
import { AlertController, LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  httpOptionsNoToken;
  loading: any;

  constructor(
    private platform: Platform,
    private httpClient: HttpClient,
    private alertCtrl: AlertController,
    private toasCtrl: ToastController,
    private loadingCtrl: LoadingController
  ) {
    this.httpOptionsNoToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
  }

  getUfs(): Promise<any> {
    // if (this.isCordova()) {
    //   return this.httpNative.get(`${environment.apiUrl}core/uf/`, {}, this.headers)
    // } else {
    return this.httpClient.get(`${environment.apiUrl}core/uf/`, this.httpOptionsNoToken).toPromise()
    // }
  }

  getLocalidades(cod_uf): Promise<any> {
    // if (this.isCordova()) {
    //   return this.httpNative.get(`${environment.apiUrl}core/localidades/?uf=${cod_uf}`, {}, this.headers)
    // } else {
    return this.httpClient.get(`${environment.apiUrl}core/localidades/?uf=${cod_uf}`, this.httpOptionsNoToken).toPromise()
    // }
  }

  getSetores(): Promise<any> {
    const domain = localStorage.getItem('domain');
    return this.httpClient.get(`https://${domain}/v1/core/setores/`, getAuthHeader()).toPromise()
  }

  buscaLogradouro(cod_localidade): Promise<any> {
    const domain = localStorage.getItem('domain');
    console.log(domain, cod_localidade)
    return this.httpClient.get(`https://${domain}/v1/core/logradouros/busca/?localidade=${cod_localidade}`, getAuthHeader()).toPromise()
  }

  buscaDomicilios(no_logradouro): Promise<any> {
    const domain = localStorage.getItem('domain');
    return this.httpClient.get(`https://${domain}/v1/core/domicilios/?no_logradouro=${no_logradouro}`, getAuthHeader()).toPromise()
  }

  getSecretariasByUf(sg_uf): Promise<any> {
    // if (this.isCordova()) {
    //   return this.httpNative.get(`${environment.apiUrl}secretarias?uf=${sg_uf}`, {}, this.headers)
    // } else {
    return this.httpClient.get(`${environment.apiUrl}secretarias?uf=${sg_uf}`, this.httpOptionsNoToken).toPromise()
    // }
  }

  isCordova(): boolean {
    return this.platform.is('cordova');
  }

  saveFCMToken(registration_id, type) {
    return this.httpClient.post(`${environment.domain}devices/`, { registration_id, type }, getAuthHeader()).toPromise()
  }

  async presentAlert(header, msg) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: msg,
      buttons: ['ok']
    });

    await alert.present();
  }

  async presentLoading(seconds?) {
    this.loading = await this.loadingCtrl.create({
      message: 'Aguarde...',
      duration: seconds ? seconds : 2000
    });
    await this.loading.present();
    console.log('Loading dismissed!');
  }

  async presentToast(message, type = 'dark', buttonText = 'ok') {
    const toast = await this.toasCtrl.create({
      message: message,
      color: type,
      // cssClass: type === 'danger' ? 'saic-error-toast' : '',
      duration: 5000,
      buttons: [
        {
          side: 'end',
          role: 'cancel',
          // icon: 'star',
          text: buttonText
          // handler: () => {
          //   console.log('Favorite clicked');
          // }
        }
      ]
    });
    toast.present();
  }
}


function getAuthHeader(token?) {
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Token ${token || localStorage.getItem('auth_token')}`
    })
  };
}