import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InternacaoService } from '../services/internacao.service';

@Component({
  selector: 'app-status-add',
  templateUrl: './status-add.page.html',
  styleUrls: ['./status-add.page.scss'],
})
export class StatusAddPage implements OnInit {
  @Input() id: string;
  @Input() medico: string;
  @Input() paciente: string;

  status: string;

  constructor(
    private modalController: ModalController,
    private internacaoService: InternacaoService
  ) { }

  ngOnInit() {
    this.status = '';
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }

  async send() {
    await this.internacaoService.addStatus(this.id, this.status);
    this.modalController.dismiss(null, 'submit');
  }
}
