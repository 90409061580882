import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

// const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToAvaliacao = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToAvaliacao }
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module').then(m => m.CadastroPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToAvaliacao }
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'avaliacao-add',
    loadChildren: () => import('./avaliacao-add/avaliacao-add.module').then(m => m.AvaliacaoAddPageModule)
  },
  {
    path: 'avaliacao-add/:usuarioId',
    loadChildren: () => import('./avaliacao-add/avaliacao-add.module').then(m => m.AvaliacaoAddPageModule)
  },
  {
    path: 'avaliacao/:avaliacaoId',
    loadChildren: () => import('./avaliacao-detail/avaliacao-detail.module').then(m => m.AvaliacaoDetailPageModule)
  },
  {
    path: 'internacao/:id',
    loadChildren: () => import('./internacao-detail/internacao-detail.module').then(m => m.InternacaoDetailPageModule)
  },
  {
    path: 'bem/:nivel_risco',
    loadChildren: () => import('./bem/bem.module').then(m => m.BemPageModule)
  },
  {
    path: 'usuarios-add',
    loadChildren: () => import('./usuarios-add/usuarios-add.module').then(m => m.UsuariosAddPageModule)
  },
  {
    path: 'usuario/:usuarioId',
    loadChildren: () => import('./usuario-details/usuario-details.module').then(m => m.UsuarioDetailsPageModule)
  },
  {
    path: 'usuarios/:domicilioId',
    loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosPageModule)
  },
  {
    path: 'residencias/:co_localidade',
    loadChildren: () => import('./residencias/residencias.module').then(m => m.ResidenciasPageModule)
  },
  {
    path: 'residencia-details',
    loadChildren: () => import('./residencia-details/residencia-details.module').then(m => m.ResidenciaDetailsPageModule)
  },
  {
    path: 'domicilios/:nu_cep/:no_logradouro',
    loadChildren: () => import('./domicilios/domicilios.module').then(m => m.DomiciliosPageModule)
  },
  {
    path: 'logradouros/:nu_dne/:localidade',
    loadChildren: () => import('./logradouros/logradouros.module').then(m => m.LogradourosPageModule)
  },
  {
    path: 'territorio/:co_uf',
    loadChildren: () => import('./territorio/territorio.module').then(m => m.TerritorioPageModule)
  },
  {
    path: 'politica-privacidade',
    loadChildren: () => import('./politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadePageModule)
  },
  {
    path: 'escolher-uf',
    loadChildren: () => import('./escolher-uf/escolher-uf.module').then(m => m.EscolherUfPageModule)
  },
  {
    path: 'visita',
    loadChildren: () => import('./visita/visita.module').then(m => m.VisitaPageModule)
  },
  {
    path: 'desktop-acess',
    loadChildren: () => import('./desktop-acess/desktop-acess.module').then(m => m.DesktopAcessPageModule)
  },
  {
    path: 'pacientes',
    loadChildren: () => import('./pacientes/pacientes.module').then(m => m.PacientesPageModule)
  },
  {
    path: 'pacientes/:id',
    loadChildren: () => import('./paciente-detail/paciente-detail.module').then(m => m.PacienteDetailPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotPageModule)
  },
  {
    path: 'medicacao-add',
    loadChildren: () => import('./medicacao-add/medicacao-add.module').then(m => m.MedicacaoAddPageModule)
  },
  {
    path: 'recados',
    loadChildren: () => import('./recados/recados.module').then(m => m.RecadosPageModule)
  },
  {
    path: 'fato-ou-fake',
    loadChildren: () => import('./fato-ou-fake/fato-ou-fake.module').then(m => m.FatoOuFakePageModule)
  },
  {
    path: 'casos',
    loadChildren: () => import('./casos/casos.module').then( m => m.CasosPageModule)
  },
  {
    path: 'casos-add',
    loadChildren: () => import('./casos-add/casos-add.module').then( m => m.CasosAddPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
