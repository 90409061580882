import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-escolher-perfil',
  templateUrl: './escolher-perfil.page.html',
  styleUrls: ['./escolher-perfil.page.scss'],
})
export class EscolherPerfilPage {
  @Input() perfis: any;

  constructor(private modalCtrl: ModalController) {
  }

  select(index) {
    this.modalCtrl.dismiss({ index });
  }

  parseRole(role) {
    if (role) {
      switch (role) {
        case 'cidadao':
          return 'CIDADÃO';
        case 'medico':
          return 'MÉDICO';
        default:
          return role.toUpperCase().replace(/_/g, ' ')
      }
    } else {
      return '';
    }
  }
}
