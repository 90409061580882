import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HTTP } from '@ionic-native/http/ngx';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { environment } from '../environments/environment';
import { BemPageModule } from './bem/bem.module';
import { PoliticaPrivacidadePageModule } from './politica-privacidade/politica-privacidade.module';

import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { Network } from '@ionic-native/network/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicSelectableModule } from 'ionic-selectable';
import { EscolherPerfilPageModule } from './escolher-perfil/escolher-perfil.module';
import { StatusAddPageModule } from './status-add/status-add.module';
import { MedicacaoAddPageModule } from './medicacao-add/medicacao-add.module';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { TooltipsModule } from 'ionic4-tooltips';


registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    IonicSelectableModule,
    AngularFireAuthModule,
    NgxViacepModule,
    IonicModule.forRoot({ backButtonText: '' }),
    AppRoutingModule,
    BemPageModule,
    PoliticaPrivacidadePageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    EscolherPerfilPageModule,
    StatusAddPageModule,
    MedicacaoAddPageModule,
    TooltipsModule.forRoot()
  ],
  providers: [
    StatusBar,
    // LottieSplashScreen,
    SplashScreen,
    HTTP,
    AngularFireAuthGuard,
    // Geolocation,
    LocationAccuracy,
    Network,
    NativeStorage,
    FirebaseX,
    InAppBrowser,
    AndroidPermissions,
    NgxSpinnerService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
