import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-bem',
  templateUrl: './bem.page.html',
  styleUrls: ['./bem.page.scss'],
})
export class BemPage {
  options: AnimationOptions = {
    path: 'assets/lavar-maos.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '100%',
    margin: '0 auto',
  };
  nivel_risco: string;

  constructor(private modalCtrl: ModalController,
    private route: ActivatedRoute, private navCtrl: NavController,
    private iab: InAppBrowser) { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    if (!this.nivel_risco) {
      this.modalCtrl.dismiss()
    } else {
      this.navCtrl.navigateRoot('/tabs/tab1')
    }
  }

  ngOnInit() {
    this.nivel_risco = this.route.snapshot.paramMap.get('nivel_risco');
    console.log(this.nivel_risco);
  }

  openSource() {
    const browser = this.iab.create('https://coronavirus.saude.gov.br/sobre-a-doenca#sintomas');
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{color: red;" });
    });
    browser.close();
  }

}
