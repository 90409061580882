export const environment = {
  production: true,
  version: '2.1.1',
  // firebase: {
  //   apiKey: 'AIzaSyBZbuSUAlWWiaB0ObNdGHyuPmkfj1Lrhk8',
  //   authDomain: 'saicovid19.firebaseapp.com',
  //   databaseURL: 'https://saicovid19.firebaseio.com',
  //   projectId: 'saicovid19',
  //   storageBucket: 'saicovid19.appspot.com',
  //   messagingSenderId: '1064538129672',
  //   appId: '1:1064538129672:web:064b0ba58e9d04e14e58bd'
  //   // appId: '1:1064538129672:web:064b0ba58e9d04e14e58bd'
  // },
  // apiUrl: 'https://api.saicovid-staging.infatec.solutions/v1/',
  // defaultDomain: 'api.saicovid-staging.infatec.solutions',
  // domain: 'https://api.saicovid-staging.infatec.solutions/v1/',

  firebase: {
    apiKey: 'AIzaSyA8Ot23sGTcx5PGIPLZPRTMFqZrHfoyqYc',
    authDomain: 'saicovid19-prod.firebaseapp.com',
    databaseURL: 'https://saicovid19-prod.firebaseio.com',
    projectId: 'saicovid19-prod',
    storageBucket: 'saicovid19-prod.appspot.com',
    messagingSenderId: '165160916821'
    // appId: '1:1064538129672:web:064b0ba58e9d04e14e58bd'
  },
  apiUrl: 'https://api.saic.infatec.solutions/v1/',
  defaultDomain: 'api.saic.infatec.solutions',
  domain: 'https://api.saic.infatec.solutions/v1/'

};
