import { Injectable } from "@angular/core";
import { AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Internacao } from '../internacao';

@Injectable({
  providedIn: "root",
})
export class InternacaoService {
  token: string;

  constructor(
    private httpClient: HttpClient
  ) { }

  getInternacaoByCPF(cpf) {
    const domain = localStorage.getItem('domain');
    return this.httpClient.get(`https://${domain}/v1/internacoes/pacientes/?cpf=${cpf}`, getAuthHeader()).toPromise()
  }

  async getInternacao(id) {
    const domain = localStorage.getItem('domain');
    return this.httpClient.get(`https://${domain}/v1/internacoes/pacientes/${id}/`, getAuthHeader()).toPromise();
  }

  getMyInternacoes(): Promise<any> {
    const domain = localStorage.getItem('domain');
    return this.httpClient.get(`https://${domain}/v1/internacoes/pacientes/`, getAuthHeader()).toPromise()
  }

  addPaciente(paciente): Promise<any> {
    const domain = localStorage.getItem('domain')
    return this.httpClient.post(`https://${domain}/v1/internacoes/pacientes/`, paciente, getAuthHeader()).toPromise()
  }

  addStatus(id, status) {
    const domain = localStorage.getItem('domain');
    return this.httpClient.post(`https://${domain}/v1/internacoes/pacientes/${id}/avaliacao/`, { status }, getAuthHeader()).toPromise()
  }

  addmedicacao(pacienteId, data): Promise<any> {
    const domain = localStorage.getItem('domain')
    return this.httpClient.post(`https://${domain}/v1/internacoes/pacientes/${pacienteId}/receita/`, data, getAuthHeader()).toPromise()
  }

  aplicarMedicacao(receitaId): Promise<any> {
    const domain = localStorage.getItem('domain')
    return this.httpClient.post(`https://${domain}/v1/internacoes/receitas/${receitaId}/aplicacoes/`, {}, getAuthHeader()).toPromise()
  }
}

function getAuthHeader() {
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('auth_token')}`
    })
  };
}
