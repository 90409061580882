import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController } from '@ionic/angular';
// import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { CoreService } from './services/core.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private navCtrl: NavController,
    private statusBar: StatusBar,
    private firebaseX: FirebaseX,
    private coreService: CoreService
  ) {
    this.initializeApp();
  }

  checkNotificationPermission() {
    console.log("Checking permission");
    this.firebaseX.hasPermission().then(hasPermission => {
      if (hasPermission) {
        console.log("Permission granted");
      } else {
        console.log("Requesting permission");
        this.firebaseX.grantPermission().then(grantedPermission => {
          console.log("Request granted");
        })
      }
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        const type = this.platform.is('android') ? 'android' : 'ios';
        console.log('TCL: AppComponent -> initializeApp -> type', type);

        this.firebaseX.onTokenRefresh().subscribe((token: string) => {
          console.log("Token loaded");
          this.checkNotificationPermission();
          if (localStorage.getItem('fcm_token') != token) {
            localStorage.setItem('fcm_token', token);
            localStorage.setItem('device_type', type);

            if (localStorage.getItem('firebase_token')) {
              this.coreService.saveFCMToken(token, type)
                .then((data) => {
                  console.log('TCL: AppComponent -> initializeApp -> data', data)
                })
                .catch((error) => console.log('TCL: AppComponent -> initializeApp -> error', error))
            } else {
              console.log('local dosent have token')
            }
          }
        });
      }
      // this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#863e6f');
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      // this.lottieSplashScreen.hide();
      // if (this.platform.is('desktop')) {
      //   this.navCtrl.navigateRoot('desktop-acess')
      //   // console.log('web');
      // }
    });
  }
}
